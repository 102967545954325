.temperatures {

    &__menu-button-box {
        display: inline-block;
        margin: 15px;
        background-color: $primary-color-dark;
    }

    &__menu-button {
        display: block;
        color: white !important;
        font-size: 1.6rem !important;
    }

}