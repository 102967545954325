.header-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 100%;
    position: relative;
    z-index: 11;

    &__menu-items-box {
        flex: 0 0 auto;
        display: flex;
        height: 100%;
    }

    &__menu-item-ico {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 1rem;
        margin-right: .2rem;
        transition: all .4s;
        cursor: pointer;
        svg {
            width: 2.5rem;
            fill: white;
            opacity: .7;
            transition: opacity .2s;
            &:hover {
                opacity: 1;
            }
        }
        
        &--active {
            position: relative;
            background-color: $color-grey-darken-2;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                background: $light-blue;
                height: .3rem;
            }
            svg {
                width: 3rem;
                opacity: 1;
                
            }
            &:hover {
                background-color: $color-grey-darken-1;
            }
        }
    }

    &__logo {
        flex: 0 0 auto;
    }

    &__right-box {
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;

        .button {
            margin-right: .5rem;
            display: flex;
            align-items: center;
            .login-button {
                background: transparent;
                border: .1rem solid #4c4c4c;
                border-radius: 10rem;
                min-width: unset;
                padding: 1rem;
                svg {
                    fill: $complementary-green;
                    width: 1.7rem;
                    height: 1.7rem;
                }
            }
        }
    }
}