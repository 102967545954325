.btn {

    &,
    &:link,
    &:visited {
    position: relative;
    font-size: 1.6rem;
    padding: 20rem 0;
    border: $button-border;
    border-radius: $button-radius;
    display: inline-block;
    height: $button-height;
    line-height: $button-height;
    vertical-align: middle;
    padding: $button-padding;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent; // Gets rid of tap active state
    text-decoration: none !important;
    cursor: pointer;
    transition: all .2s;
    }


    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 1rem 2rem rgba(0,0,0,.2);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.2);
      transform: translateY(-1px);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
    }

    &__text--transparent {
      color: transparent;
    }

    &__loader-text {
    position: absolute;
    display: block;
    top: 0;
    text-align: center;
    width: 100%;
    animation: .8s ease-out 0s 1 slideOutButtonText forwards;
    }

    &__loader {

        content: "";
        font-size: 1.6rem;
        transform: scale(0.6);
        color: white;
        position: absolute;
        border-radius: 50%;
        width: .1rem;
        height: .1rem;
        right: calc((#{$button-height} - 1.6rem - .4rem) / 2);
        top: calc((#{$button-height} - 1.6rem - .4rem) / 2);
        height: 1.6rem;
        width: 1.6rem;
        border-top: .2rem solid rgba($color-white, 0.2);
        border-right: .2rem solid rgba($color-white, 0.2);
        border-bottom: .2rem solid rgba($color-white, 0.2);
        border-left: .2rem solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load 1.1s infinite linear;
        animation: load 1.1s infinite linear;

    }

}
