//enable mouse pointer
div[class^="simplebar-content-wrapper"] {
    pointer-events: auto;
    height: auto !important;
}

@media print {
    .side-nav {
        display: none !important;
    }
}

.side-nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style: none;
    margin: 0 !important; 
    border-top: 0 !important;
    border-left: 0 !important;
    background-image: linear-gradient(#00A0E3, #B0CB1F);
    visibility: visible;
    overflow:hidden;
    border-radius: 0 0 10px 0;

    &--bg-grey {
        flex: 0 0 40%;
        z-index: 10;
        height: 100%;
        background-color: $color-grey-darken-4;
        display: flex;
        flex-direction: column;
    }

    &--hidden {
        transform: translateX(-100%);
        overflow: hidden;
    }

}