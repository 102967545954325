@keyframes shake {
    10%, 90% {
      transform: translateX(-0.1rem);
    }
    
    20%, 80% {
      transform: translateX(0.2rem);
    }
  
    30%, 50%, 70% {
      transform: translateX(-0.4rem);
    }
  
    40%, 60% {
      transform: translateX(0.4rem);
    }
}

@keyframes opacity {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

@keyframes pulse {
  0% { transform: scale(0.7); opacity: 0.5; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.7); opacity: 0.5; }

}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(40deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(40deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translateX(0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes slideOutButtonText {
  from {
    transform: translateX(-1.5rem);
  }
  to {
    transform: translateX(-2.2rem) scale(0.8);
  }
}

@-webkit-keyframes jump {
  0%{
	-webkit-transform: translateX(-50%) translateY(-25%);
	transform: translateX(-50%) translateY(-25%);
  }
  50%{
	-webkit-transform: translateX(-50%) translateY(-70%);
	transform: translateX(-50%) translateY(-70%);
  }
  100%{
	-webkit-transform: translateX(-50%) translateY(-25%);
	transform: translateX(-50%) translateY(-25%);
  }
}

@keyframes jump {
  0%{
	transform: translateX(-50%) translateY(-25%);
  }
  50%{
	transform: translateX(-50%) translateY(-70%);
  }
  100%{
	transform: translateX(-50%) translateY(-25%);
  }
}

@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg);  /* IE 9 */
      transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
      -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg);  /* IE 9 */
      transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes go-away-left {
  0% { transform: translateX(0%); }
  100%   { transform: translateX(-100%); }
}

@keyframes go-away-right {
  0% { transform: translateX(0%); }
  100%   { transform: translateX(100%); }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}