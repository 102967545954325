.overlay {
    position: fixed; /* Sit on top of the page content */
    visibility: hidden;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    opacity: 0;
    transition: opacity .5s ease-in;

    &--active {
        @media #{$small-and-down} {
            visibility: visible;
            //opacity is handled by javascript
        }
    }
}