.logged-header {
    background-color: $color-grey-darken-4;
    display: block;
    padding: 0 .2rem;
    flex-basis: 5rem;
    

    .login-button {

        background-color: $color-grey-darken-1;

        &__setting-icon {
            color: $color-white;
        }

    }

}

.content-container {
    flex-grow: 1;
    position: relative;

    .full-width-layer {
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: row;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        z-index: 2;
        visibility: hidden;

        &--upper {
            z-index: 3; 
        }
    }

}

/*

*/