.fuel-container {

    display: flex;
    flex-direction: column;
    flex: 1;

    .header {
        flex: 0 0 5rem;
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 5px;

        &__graphic {
            flex: 0 0 50%;
            background-color: white;
            margin-bottom: 0rem;
        }

    }

}