.cerulean-white-medium {
    width: 8rem;
    color: $cerulean;
    fill: white;
}

.icon-header-menu {
    width: 3rem;
    height: 3rem;
}
