/** @format */

.collapsible {
  border-top: 1px solid $collapsible-border-color;
  border-right: 1px solid $collapsible-border-color;
  border-left: 1px solid $collapsible-border-color;
  margin: $element-top-margin 0 $element-bottom-margin 0;
  @extend .z-depth-1;
  overflow-y: scroll;
}

// sideNav collapsible styling
.side-nav,
.side-nav.fixed {
  .collapsible-header {
    background-color: transparent;
    border: none;
    line-height: inherit;
    height: inherit;
    padding: 0 $sidenav-padding;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    i {
      line-height: inherit;
    }
  }

  .collapsible-body {
    border: 0;
    background-color: $collapsible-header-color;

    li a {
      padding: 0 (7.5px + $sidenav-padding) 0 (15px + $sidenav-padding);
    }
  }
}
