#progress-wrapper {

    .progress-section {
        position: fixed;
        top: 0;
        width: 50%;
        height: 100%;
        background: #222222;
        z-index: 99999;
        opacity: 0.8;
        will-change: transform;
        transition: transform 5s ease-out;

        &--left {
            left: 0;

            &--go-away {
                animation-name: go-away-left;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }

        }

        &--right {
            right: 0;

            &--go-away {
                animation-name: go-away-right;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }

    }

    .progress-circle {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #212121; /* old  #3498db */

    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    z-index: 999999;

        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: #039BE6; /* old  #e74c3c */

            -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
            animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: #FFF; /* old  #f9c922 */

            -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
            animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
        }

    }

}
