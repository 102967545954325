.modal-driver {
    position: fixed; /* Stay in place */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: flex;
    flex-direction: column;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    z-index: 99999;
    will-change: opacity;
    animation: fadein 300ms ease-in;

    &--closing {
        animation: fadeout 300ms ease-in;
        opacity: 0;
    }

    &__close {
        flex-grow: 1;
    }

    &__row-contact {
        flex: 0 1 4rem;
        font-size: 1.6rem;
        background-color: $color-grey-lighten-1;
        color: lighten($color-grey-lighten-1, 50%);
        display: flex;
        align-items: center; 
        justify-content: center;
    }

    &__row-call-and-mail {
        flex: 0 1 15%;
        background-color: $color-grey-darken-4;
        display: flex;
        align-items: center; 
        justify-content: space-around;
    }
}
