/** @format */

.asset-card {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  color: white;
  display: block;
  position: relative;
  user-select: none;

  &__header-container {
    min-height: 4.5rem;
    display: flex;
    padding: 0;
    flex-flow: row wrap;
    background-color: rgba(0, 0, 0, 0.4);
    margin: 0.3rem;
    border-radius: 10rem;
    transition: all 0.2s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }

    #input-editing {
      padding-top: 0px;
      padding-bottom: 0px;
      color: black;
      background-color: white;
      height: 2rem;
      font-size: 1.5rem;
      width: 90%;
      line-height: 1.5rem;
      margin-bottom: 0px;
    }

    &--trigged {
      margin-bottom: 0;
      border-top-left-radius: 2.2rem;
      border-top-right-radius: 2.2rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10rem;
        height: 4.5rem;
        margin: 0 0.3rem 0.3rem;
        z-index: -1;
        transition: all 0.2s;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        &:before {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
      .asset-icon {
        path {
          fill: $complementary-green;
          opacity: 1;
        }
      }
    }

    &--small {
      min-height: 4rem;
      margin: 0 0 0.5rem;
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.6);
      font-weight: lighter;
      line-height: 1.3rem;
    }
  }
  &--iconContainer {
    background: rgba(0, 0, 0, 0.4);
    margin: 0 0.5rem 0 0;
    align-self: center;
    height: 100%;
    border-radius: 10rem;
    padding: 1rem;
    justify-content: center;
    display: flex;
  }
  &--icon {
    margin: 0;
    path {
      fill: white;
      opacity: 0.8;
    }
  }

  &__name {
    flex: 0 0 35%;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    align-self: center;
    margin: 0.2rem 0;
    user-select: none;
  }

  &__allOnOff {
    flex: 1;
    font-size: 1.6rem;
    color: $color-white;
    align-self: stretch;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    border-radius: 0;
    margin: 0;
  }

  &__sticker {
    display: inline-block;
    font-size: 1rem;
    height: 1.2rem;
    line-height: 1.2rem;
    background: transparent;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  &__info-box {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.1rem 0;
  }

  &__info-box-row {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    line-height: 1.1rem;
    background: $color-grey-darken-3;
    border-radius: 10rem;
    padding: 0.3rem 0;
    p {
      padding: 0rem 1rem 0 0.5rem;
    }
  }

  &__icon-play {
    font-size: 1.6rem;
    animation: pulse 1.5s infinite ease-in-out;
    margin: 0.4rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 10rem;
    background: $complementary-green;
    margin-left: 0.7rem;
  }

  &__icon-stop {
    font-size: 1.6rem;
    background: $complementary-red;
    margin: 0.4rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 10rem;
    margin-left: 0.7rem;
  }

  &__icon-dead {
    font-size: 1.6rem;
    background: rgba(149, 165, 166, 0.6);
    margin: 0.4rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 10rem;
    margin-left: 0.7rem;
  }

  &__signal-icon {
    margin: 0.4rem;
  }
  &__body {
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.4);
    margin: 0 0.3rem 0.3rem;
    padding: 0.5rem 0;
    flex: 1;
    border-radius: 0 0 2.2rem 2.2rem;
  }

  &__info-row {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.5rem 1rem 0.5rem 0.7rem;
    margin: 0.5rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10rem;

    svg {
      width: 2rem;
      fill: $complementary-green;
    }
    p {
      text-align: center;
      width: 100%;
      margin-left: -2rem;
    }

    &--active:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &--hovered {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__location-button {
    &__row {
      flex-direction: row;
      display: flex;
      text-align: center;
      width: 100%;
      align-items: center;

      i {
        margin-right: 0.5rem;
      }
      p {
        display: inline-block;
        margin-left: -24px;
        padding: 0 24px;
      }
    }
  }
}
