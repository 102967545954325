/** @format */

.group-card {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  color: white;
  display: block;
  position: relative;
  user-select: none;
  margin-bottom: 0.3rem;

  &__header-container {
    min-height: 2.5rem;
    display: flex;
    padding: 0;
    flex-flow: row wrap;
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 10rem;
    transition: all 0.2s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &--trigged {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      border-top-left-radius: 2.2rem;
      border-top-right-radius: 2.2rem;
      border-bottom-left-radius: 2.2rem;
      border-bottom-right-radius: 2.2rem;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10rem;
        height: 4.5rem;
        margin: 0 0.3rem 0.3rem;
        z-index: -1;
        transition: all 0.2s;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        &:before {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
      .asset-icon {
        path {
          fill: $complementary-green;
          opacity: 1;
        }
      }
    }
  }

  &__name {
    flex: 0 0 100%;
    display: flex;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    margin: 0.2rem 0;
    user-select: none;
  }
}
