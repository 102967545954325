.login-form {
    background-color: rgba($color-black, 0.5);
    backface-visibility: hidden;
    padding: 0em 2em 2em;
    width: 34rem;
    margin-top: 10vh;
    font-size: 1.6rem;
    border: .2em solid white;

    @media #{$small-and-down} {
        width: 90vw;
    }

    &--h1 {
        font-size: 3.2rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    &--a {
        color: white;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
    }


    label, input {
        font-size: 1.6rem;
        transition: all 0.2s;
        touch-action: manipulation;
    }

    input:focus + label {
        bottom: 100%;
        margin-top: -1.6rem;
        font-size: 1rem;
    }

    &--error-box {
        color: $error-color;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.6em;
        text-align: center;
        animation: opacity 0.82s ease-out both;
        cursor:pointer;
    }  

    &--border-error {
        border-color: $error-color;
    }

    &--info-box {
        color: $success-color;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.6em;
        text-align: center;
        animation: opacity 0.82s ease-out both;
        cursor:pointer;
    }  

    &--info-error {
        border-color: $success-color;
    }


}