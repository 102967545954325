/**
 * /* Text Inputs + Textarea
 *    ==========================================================================
 *
 * @format
 */

/* Text inputs */

input:not([type]):not([class*='Mui']),
input[type='text']:not(.browser-default):not([class*='Mui']),
input[type='password']:not(.browser-default):not([class*='Mui']),
input[type='email']:not(.browser-default):not([class*='Mui']),
input[type='url']:not(.browser-default):not([class*='Mui']),
input[type='time']:not(.browser-default):not([class*='Mui']),
input[type='date']:not(.browser-default):not([class*='Mui']),
input[type='tel']:not(.browser-default):not([class*='Mui']),
input[type='number']:not(.browser-default):not([class*='Mui']),
input[type='search']:not(.browser-default):not([class*='Mui']) {
  //Hide autofill background
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }

  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: $input-border;
  border-radius: 0;
  outline: none;
  height: $input-height;
  width: 100%;
  font-size: $input-font-size;
  margin: $input-margin;
  padding: $input-padding;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;

  // Disabled input style
  &:disabled,
  &[readonly='readonly'] {
    color: $input-disabled-color;
    border-bottom: $input-disabled-border;
  }

  // Disabled label style
  &:disabled + label,
  &[readonly='readonly'] + label {
    color: $input-disabled-color;
  }

  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
  }

  // Focused label style
  &:focus:not([readonly]) + label {
    color: $input-focus-color;
  }

  &:focus + label {
    bottom: 100%;
    margin-top: -1.8rem;
    font-size: 1rem;
  }

  &:not([value='']) + label,
  &:-webkit-autofill + label,
  &:-webkit-autofill:hover + label,
  &:-webkit-autofill:focus + label,
  &:-webkit-autofill:active + label {
    bottom: 100%;
    margin-top: -1.8rem;
    font-size: 1rem;
  }

  & + label + .error {
    opacity: 0;
    top: 100%;
    margin-top: -1.8rem;
    font-size: 1rem;
    color: $error-color;
  }

  & + label + .warning {
    opacity: 0;
    top: 100%;
    margin-top: -1.8rem;
    font-size: 1rem;
    color: $color-orange;
  }

  & + label + .info {
    opacity: 0;
    top: 100%;
    margin-top: -1.8rem;
    font-size: 1rem;
    color: $success-color;
  }

  &:not([value='']):invalid + label + .error {
    opacity: 1;
  }
}

.input--white {
  color: white;
}

// Styling for input field wrapper
.input-field {
  width: 100%;
  // Inline styles
  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    input,
    .select-dropdown {
      margin-bottom: 1rem;
    }
  }

  // Gutter spacing
  &.col {
    label {
      left: $gutter-width / 2;
    }

    .prefix ~ label,
    .prefix ~ .validate ~ label {
      width: calc(100% - 3rem - #{$gutter-width});
    }
  }

  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > label {
    color: $input-border-color;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);

    &:not(.label-icon).active {
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
  }
}
