/** @format */

.log-book {
  color: $color-white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &__asset-selection {
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    flex: 0 0 6rem;
    z-index: 0;
    position: relative;

    &__asset-icon {
      flex: 0 0 22%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      svg {
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    &__button-box {
      flex-grow: 1;
      display: flex;
      box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
    }

    button {
      color: $color-white;
      font-weight: 700;
      font-size: 2rem;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: flex-start;
      display: flex;
      padding-left: 0;

      .menu-button__name-box {
        display: flex;
        flex-direction: column;
        flex: 0 0 68%;
        align-items: flex-start;
      }

      .menu-button__icon-open {
        align-self: stretch;
        flex: 1 0 15%;
        color: $color-grey-lighten-1;
        &__svg {
          height: 100%;
        }
      }

      .menu-button__asset-name {
        flex: 0 0 90%;
        line-height: 2.7rem;
      }

      .menu-button__asset-sticker {
        flex: 0 0 10%;
        color: white;
        opacity: 0.6;
        font-size: 1.4rem;
      }
    }
  }

  &__menu-list {
    min-width: 30rem;
    text-transform: uppercase;
  }

  &__activity-summary-box {
    /*margin: 0 -2px -2px 0; */
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    align-content: stretch;
    flex: 0 0 auto;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__activity-item {
    align-self: stretch;
    text-align: center;
    padding: 1rem 0 1.2rem 0;
    font-size: 1rem;
    flex-grow: 1;
    color: white;
    display: flex;
    flex-direction: column;

    & .units {
      color: white;
      opacity: 0.7;
      font-size: 1rem;
    }

    & .line-1 {
      flex: 1 0 45%;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 10rem;
      margin: 0.3rem;
      display: flex;
      align-items: center;
      p {
        text-align: center;
        width: 100%;
        margin-left: -0.6rem;
      }
    }

    & .line-2 {
      flex: 1 0 50%;
      color: $color-white;
      font-size: 1.6rem;
      font-weight: 700;
    }

    & .icon {
      width: 1.3rem;
      height: 1.3rem;
      fill: $complementary-green;
      margin-left: 0.6rem;
    }
  }

  & .date-selector-container {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    align-self: stretch;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 1rem 2rem;

    .header {
      display: flex;
      justify-content: space-between;
      flex: 1 0 100%;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 4rem;
      padding-left: 1.5rem;
    }

    & .button {
      border: 2px solid white;
      min-width: calc(50% - 2rem);
      margin: 0.5rem;
      border-radius: 10rem;
      font-size: 1.4rem;
      height: 3.2rem;
      line-height: calc(3.2rem - 4px);
      text-align: center;
      vertical-align: middle;
      color: white;
      cursor: pointer;

      &:hover {
        border-color: white;
        background-color: white;
        color: $primary-color;
      }
      transition: all 0.2s ease-out;

      &--active {
        background-color: white;
        border-color: white;
        color: $primary-color;
        z-index: 2;
      }
      @media (max-width: 600px) {
        min-width: calc(50% - 2rem);
        margin: 0.5rem;
        border-radius: 0.4rem;
      }
    }

    & .date-picker {
      flex: 0 0 88%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      &__box {
        flex: 1 0 50%;
        padding: 1rem;
        color: white;
        font-size: 1.6rem;
        height: 3.2rem;
        line-height: 3.2rem;
        display: flex;
        justify-content: space-around;
        margin-top: 1rem;

        & .input-box {
          height: 3.2rem;

          &__field {
            vertical-align: middle;
            align-self: center;
          }
        }
      }
    }
  }

  & .day-header {
    background-color: transparent;
    box-shadow: none;

    &__p {
      display: flex;
      align-self: stretch;
      flex-grow: 1;
      font-size: 1.6rem;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-top: -12px;
      margin-bottom: -12px;
      margin-left: -1rem;
      & svg {
        width: 2.5rem;
        height: 2.5rem;
        fill: white;
        display: inline-block;
      }
    }

    &__checkbox {
      display: inline-block;
      margin-left: 10px;

      label {
        margin-left: 10px;
      }
    }

    &__icon {
      flex: 0 0 5%;
      background: rgba(0, 0, 0, 0.2);
      align-self: center;
      border-radius: 10rem;
      padding: 1rem;
      justify-content: center;
      display: flex;
    }

    &--white {
      color: $color-white;
    }

    &__date {
      display: inline-block;
      flex-grow: 1;
      text-align: center;
      font-size: 2rem;
      font-weight: 300;
    }

    &__trips-block {
      display: inline-flex;
      flex: 1 0 40%;
      max-width: 14rem;
      font-size: 1.3rem;
      font-weight: bold;

      &__count {
        background-color: $complementary-green;
        z-index: 3;
        border-radius: 20px;
        flex: 0 0 20%;
        min-width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        background-color: $color-grey-darken-3;
        border-radius: 20px;
        padding-left: 1.5rem;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-20%);
        flex-grow: 1;
      }
    }
  }

  &__day-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 2rem;

    &--error {
      font-size: 2rem;
      text-align: center;
    }
  }
}

.day-body {
  padding: 0 !important;
  display: flex !important;
  flex-direction: column !important;

  &__row:hover {
    background: $color-grey-darken-1;
  }

  &__row:nth-child(2n + 0) {
    background: rgba(0, 0, 0, 0.3);
  }

  &__row:nth-child(2n + 0):hover {
    background: $color-grey-darken-1;
  }

  &__row {
    background: rgba(0, 0, 0, 0.6);
    transition: background-color 0.15s ease-out;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-right: 0.5rem;
    margin: 0 0.5rem;
    cursor: pointer;

    &--active {
      background: $color-grey-darken-1 !important;
      border: 2px solid $complementary-orange;
    }

    &:last-child {
      border-radius: 0 0 2.5rem 2.5rem;
    }
    & .left-column {
      flex: 0 0 7%;
      display: inline-block;
      position: relative;
      &__row-number {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        line-height: 1.4rem;
        color: $color-white;
        font-size: 1rem;
        font-weight: bold;
        padding-right: 1rem;
        z-index: 1;
        position: absolute;
        background: linear-gradient(
          to bottom right,
          $primary-color 0%,
          $primary-color 50%,
          transparent 50%,
          transparent 100%
        );
      }

      &__date {
        display: block;
        position: relative;
        color: white;
        width: 2.5rem;
        height: 6.9rem;
        line-height: 2.5rem;
        font-size: 1.4rem;

        &--start {
          background-color: $color-grey-darken-2;
          .vertical-text {
            transform: translateY(100%) rotate(-90deg);
          }
        }

        &--stop {
          background-color: $color-grey-darken-4;
          .vertical-text {
            transform: translateY(140%) rotate(-90deg);
          }
        }
      }
    }

    & .address {
      flex: 0 0 53%;
      font-size: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 5px 10px 10px;
      margin-left: 0.5rem;
      &__start-stop {
        flex: 1 0 50%;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0 0;
        position: relative;
        p {
          font-size: 1.7rem;
        }
        .number {
          display: inline-block;
          position: absolute;
          padding: 0.3rem 0.3rem;
          text-align: center;
          line-height: 1.4rem;
          color: black;
          font-size: 1rem;
          font-weight: bold;
          z-index: 1;
          background: white;
          left: -1.5rem;
          width: 3.3rem;
          padding-right: 2rem;
        }
        &--header {
          display: flex;
          align-items: flex-end;
          width: 13rem;
          border-radius: 3rem;
          font-size: 1.3rem;
          background: $color-grey-darken-3;
          position: relative;
          z-index: 2;
          svg {
            fill: $color-white;
            height: 2.4rem;
            width: 2.4rem;
            margin: 0 0.3rem 0 0.3rem;

            padding: 0.3rem;
            border-radius: 50%;
          }
          .bold {
            color: $color-white;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.5rem;
          }

          .normal {
            color: $color-white;
            margin-left: 5px;
            font-weight: 400;
            line-height: 2.5rem;
          }
        }
      }
    }

    & .info-tile-container {
      display: none;
      flex: 1;
      font-size: 1.4rem;
      display: flex;
      flex-wrap: wrap;
      margin: 2rem 0;
      &__tile {
        flex: 1 0 45%;
        text-align: center;
        flex-direction: column;
        display: flex;
        margin: 0 4px;
        font-size: 1.6rem;
        & .header {
          font-size: 1.2rem;
          line-height: 2rem;
          display: flex;
          align-items: center;
          color: white;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 10rem;
          & svg {
            fill: $complementary-green;
            height: 1.1rem;
            width: 1.1rem;
            margin-left: 0.5rem;
            position: relative;
          }
          span {
            width: 100%;
            margin-left: -1.1rem;
          }
        }

        .consumRow {
          flex-direction: row;
        }
        & .units {
          font-size: 0.6rem;
          opacity: 0.8;
        }
      }
    }
  }
}
