@mixin scroll-bar {
    &::-webkit-scrollbar {
        width: 15px;
        position: relative;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: $color-grey-darken-3;
    }

    &::-webkit-scrollbar-thumb {
        background-color: grey;
        border-radius: 10px;
        height: 30%;
    }
}

@mixin complementary-gradient($opacity, $color1, $color2) {
    background-image: linear-gradient(rgba($color1, $opacity),rgba($color2, $opacity));
}

@mixin popupWindow($color1, $color2) {
    &.leaflet-popup {
        .leaflet-popup-content-wrapper {
            @include complementary-gradient(1, $color1, $color2);
            .leaflet-popup-content {
                p {
                    justify-content: center;
                }
            }
        }
        .leaflet-popup-tip {
            background: $color2;
        }
    }
}