.login-wrapper {
    height: 100%;
    width: 100vw;
    overflow-y:scroll;
    background-image: linear-gradient(90deg, $cerulean, $key-lime-pie);
    background-size: cover;
    @extend %white-text;

    svg {
        &.logo {
          color: white;
          height: 5rem;
          top: 5rem;
          left: 15vw;
          position: relative;
        }
    }

    @media #{$small-and-down} {
        svg {
            &.logo {
              top: 1rem;
              left: 0;
              right: 0;
              display: block;
              margin: 0 auto;
            }
          }
    }
 
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}