/** @format */

.marker-container {
  z-index: 198 !important;
  width: 1px !important;
  height: 1px !important;
  margin-left: 0 !important;
  margin-top: 0 !important;

  &--running {
    z-index: 199 !important;
    .placeholder-map {
      fill: green;
    }
    .marker-map__sticker-plate {
      z-index: 199 !important;
      border: 2px solid green;
      overflow: visible;
      position: relative;

      &__arrow {
        background-color: green;
        width: 20px;
        height: 20px;
        top: -10px;
        right: -10px;
        position: absolute;
        clip-path: polygon(30% 60%, 50% 0%, 70% 60%, 50% 48%);
      }
    }

    .marker-map {
      svg {
        fill: green;
      }
    }
  }

  &--paused {
    z-index: 199 !important;
    .placeholder-map {
      fill: orange;
    }
    .marker-map__sticker-plate {
      z-index: 199 !important;
      border: 2px solid orange;
    }

    .marker-map {
      svg {
        fill: orange;
      }
    }
  }

  &--dead {
    z-index: 197 !important;
    .placeholder-map {
      fill: rgba(149, 165, 166, 0.6);
    }

    .marker-map {
      svg {
        fill: rgba(149, 165, 166, 0.6);
      }
    }

    .marker-map__sticker-plate {
      z-index: 197 !important;
      border: 2px solid rgba(149, 165, 166, 0.6);
    }
  }
}

.marker-map {
  z-index: 198 !important;
  min-width: 3rem;
  display: inline-block;
  transform: translateX(-50%) translateY(-25%);
  text-align: center;

  &__popup {
    width: 200px;
  }

  & svg {
    fill: $complementary-red;
    padding: 0.5rem;
    height: 3rem;
    width: 3.5rem;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    margin-bottom: -0.5rem;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.6);
  }

  &--jumping {
    z-index: 201 !important;
    -webkit-animation: jump 0.7s cubic-bezier(0.31, 0.44, 0.445, 1.65) 0s 2 normal;
    animation: jump 0.7s cubic-bezier(0.31, 0.44, 0.445, 1.65) 0s 2 normal;
  }

  &__sticker-plate {
    background: rgba(255, 255, 255, 0.75);
    color: $color-black;
    border-radius: 0.5em;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.3rem;
    min-width: 6rem;
    text-align: center;
    padding: 4px 5px 3px 5px;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    border: 2px solid $complementary-red;
    font-family: 'Roboto', sans-serif;
    position: relative;
  }
}

.marker-map__sticker-plate {
  z-index: 198 !important;
  background: rgba(255, 255, 255, 0.75);
  color: $color-black;
  border-radius: 0.5em;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.3rem;
  min-width: 6rem;
  text-align: center;
  padding: 4px 5px 3px 5px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
  border: 2px solid $complementary-red;
  font-family: 'Roboto', sans-serif;
}

.placeholder-map {
  z-index: 198 !important;
  min-width: 3rem;
  display: inline-block;
  transform: translateX(-33%) translateY(-25%);
  text-align: center;
  fill: $complementary-red;
}

.marker-map__popup {
  box-sizing: border-box;
  width: auto;

  &.leaflet-popup {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: rgba(255, 255, 255, 0.9);
    }
    .leaflet-popup-content {
      font-family: 'Roboto', sans-serif;
      color: $color-black;
      position: relative;
      margin: 0;

      .popup-box {
        display: flex;
        height: 100%;
        align-content: stretch;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__left-content {
          flex-grow: 1;
        }

        &__right-content {
          flex: 1;
          padding-top: 5px;
          align-self: flex-start;
        }
      }

      .line {
        margin: 10px 0 5px 5px;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.5rem;
        display: flex;
        svg {
          width: 2.5rem;
          height: 2rem;
          margin-right: 5px;
        }
      }
      .signal {
        margin-right: 5px;
        margin-left: 5px;
        width: 20px;
        height: 100%;
        svg {
          fill: white;
          width: 20px;
          height: 20px;
        }
      }
    }
    .leaflet-popup-close-button {
      background: black;
      color: white;
      width: auto;
      height: auto;
      padding: 0.5rem;
      border-radius: 10rem;
      top: -0.7rem;
      right: -0.7rem;
    }
  }
  &--warning {
    @include popupWindow($complementary-red, $complementary-orange);
  }
  &--info {
    @include popupWindow($primary-color, $complementary-green);
  }
  &--border {
    border: 2px solid $complementary-orange;
  }
}
