// 1. Colors
// ==========================================================================

// Variables
$primary-color: #039be6;
$primary-color-light: #8bcae5;
$primary-color-dark: #29b6f6;

$secondary-color: rgb(3, 169, 244);
$success-color: #4caf50;
$error-color: #d64d4d;

$font-stack: "Roboto", sans-serif;
$cerulean: #00a1e6;
$light-blue-lighten-2: #4fc3f7;
$light-blue: #03a9f4;
$key-lime-pie: #afcb20;
$complementary-green: #B0CB1F;
$complementary-red: #EA556F;
$complementary-orange: #F6AD71;
$color-black: #000;
$color-white: #fefefe;
$color-grey-lighten-1: #bdbdbd;
$color-grey: rgba(0, 0, 0, .5);
$color-grey-darken-1: #5a5a5a;
$color-grey-darken-2: #373737;
$color-grey-darken-3: #2a2a2a;
$color-grey-darken-4: #252525;
$color-grey-darken-5: #1c1c1c;
$color-grey-text: rgba(0, 0, 0, .7);
$color-grey-background-lighten: rgb(250,250,250);

$color-orange: rgba(255, 152, 0, 1);
$color-orange-dark: rgba(75, 45, 0, 1);
$color-orange-light: rgba(255, 152, 0, 0.4);

$placeholder-text-color: #fff;

// Switches
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(lighten($switch-bg-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: rgba(0,0,0,.38) !default;
$switch-radius: 15px !default;
$switch-active-bg: #dcdcdc !default;

// 3. Buttons
// ==========================================================================

// Shared styles
$button-border: 2px solid !default;
$button-background-focus: lighten($secondary-color, 4%) !default;
$button-font-size: 1.6rem !default;
$button-icon-font-size: 1.3rem !default;
$button-height: 3.6rem !default;
$button-padding: 0px 1.6rem !default;
$button-radius: 2px !default;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// 6. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

// 9. Dropdown
// ==========================================================================

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $secondary-color !default;
$dropdown-item-height: 5rem !default;


// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 2.5rem !default;
$input-border-color: #fff !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $secondary-color !default;
$input-font-size: 1.6rem !default;
$input-margin-bottom: .8rem;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 1rem 0 .2rem 0 !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$input-icon-size: 3.6rem;
$placeholder-text-color: lighten($input-border-color, 20%) !default;

// 12. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

// 15. Side Navigation
// ==========================================================================

$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0,0,0,.87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: 48px !default;
$sidenav-line-height: $sidenav-item-height !default;