/** @format */

.map-container {
  flex: 1;
  z-index: 2;
  visibility: visible;
  height: 100%;

  &__history-button-box {
    position: fixed;
    bottom: 18px;
    right: 5px;
    display: inline-block;
    z-index: 9999;
  }
}

.leaflet-container {
  height: 100%;
}

.geolocation-icon {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: url(../artefacts/mylocation.svg);
  background-size: 90% 90%;
  background-position: 48% 50%;
  background-repeat: no-repeat;
}

#mapid {
  height: 100%;
}
